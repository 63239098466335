import Vue from "vue";
import Vuex from "vuex";

import axios from "axios";
import Service from "../utils/general";
//import Menu from "./services/menu";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: "",
    token: sessionStorage.getItem("token") || "",
    snackbar: {
      visible: false,
      color: "success",
      text: "",
      timeout: 2500,
      multiline: false,
    },
    role: sessionStorage.getItem("ykilsa") || 0,
    user: {
      name: Service.getUser() ? Service.getUser().name : "",
      role: Service.getUser() ? Service.getUser().rol : "",
    },
    postulante: {
      id: "",
      nombre: "",
      modalidad: "",
      cedula_dni:"",
      complemento:"",
      fecha_nacimiento:"",
    },
  },
  mutations: {
    authSuccess(state, token) {
      state.token = token;
      state.status = "success";
      Service.setToken(token);
    },
    authError(state) {
      state.token = "";
      state.status = "error";
      Service.removeToken();
    },
    authLogout(state) {
      state.token = "";
      state.status = "";
      Service.removeToken();
    },
    setRole(state, data) {
      state.role = data.iro;
      state.user.name = data.name;
      state.user.role = data.rol;
      //Menu.setRole(role);
    },
    postulanteData(state, payload) {
      state.postulante = payload;
    },
    openToast(state, payload) {
      state.snackbar.text = payload.text;
      state.snackbar.multiline = payload.text
        ? payload.text.length > 50
        : false;
      if (payload.multiline) {
        state.snackbar.multiline = payload.multiline;
      }
      if (payload.color) {
        state.snackbar.color = payload.color;
      }
      if (payload.timeout) {
        state.snackbar.timeout = payload.timeout;
      }
      state.snackbar.visible = true;
    },
    closeToast(state) {
      state.snackbar.visible = false;
      state.snackbar.multiline = false;
      state.snackbar.text = null;
    },
  },
  actions: {
    login({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(Service.getLogin(), payload)
          .then(response => {
            let token = response.data.token;
            if (response.data.status === "success") {
              Service.setUser(response.data);
              commit("authSuccess", token);
              commit("setRole", response.data);
              resolve({
                status: response.data.status,
                msg: `${response.data.message} ${response.data.name}`,
              });
            } else {
              reject({ status: "error", msg: response.data.message });
            }
          })
          .catch(err => {
            //console.log(err.message);
            Service.removeUser();
            commit("authError", "Error en autenticación");
            reject({ status: "error", msg: err }); //revisar si viene en data
          });
      });
    },
    logout({ commit }) {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        Service.removeUser();
        commit("authLogout");
        commit("setRole", 0);
        resolve();
      });
    },
    postulante({ commit }, payload) {
      // eslint-disable-next-line
      commit("postulanteData", payload);
    },
    toast(context, payload) {
      context.commit("openToast", payload);
    },
    closeToast(context) {
      context.commit("closeToast");
    },
  },
  getters: {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status,
    getSnackBar: state => state.snackbar,
    getPostulante: state => state.postulante,
    getRole: state => state.role,
    getUser: state => state.user,
  },
});
