<template>
  <div>
    <v-app-bar color="secondary darken-1" fixed dark app>
      <v-app-bar-nav-icon @click.stop="drawerToggle"></v-app-bar-nav-icon>
      <v-toolbar-title class="body-2" v-if="isAuth">
        {{ userauth.name ? userauth.name : "" }} <br />
        <span class="subheading">
          <b class="subtitle-2">{{ userauth ? userauth.role : "" }}</b>
        </span>
      </v-toolbar-title>
      <v-toolbar-title v-else>EGI</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="fullScreen()">
        <v-icon>{{ icon_fs }}</v-icon>
      </v-btn>
      <v-btn v-if="!isAuth" color="primary" dark @click="showDialogLoggin">
        <v-icon>mdi-user</v-icon>Ingresar
      </v-btn>
      <v-menu
        v-else
        offset-y
        origin="center center"
        :nudge-bottom="10"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-avatar size="42px">
              <img v-bind:src="require('../assets/user.svg')" alt="User" />
            </v-avatar>
          </v-btn>
        </template>
        <v-list class="pa-0">
          <v-list-item @click="perfil()" ripple="ripple" rel="noopener">
            <v-list-item-icon>
              <v-icon>mdi-account-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Perfil</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="logout()" ripple="ripple" rel="noopener">
            <v-list-item-icon>
              <v-icon color="error">mdi-close</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Salir</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-dialog
      v-model="dialogLoggin"
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-form ref="lform" @submit.prevent="login()">
        <v-card>
          <v-card-title>Iniciar sesión</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              v-model="user.username"
              label="Usuario/Correo"
              autocomplete="off"
              prepend-icon="mdi-account"
              filled
              :rules="[vrules.required, vrules.usuario_min5]"
            ></v-text-field>
            <v-text-field
              v-model="user.password"
              label="Contraseña"
              autocomplete="off"
              prepend-icon="mdi-key"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              filled
              :rules="[vrules.required, vrules.password_min5]"
            ></v-text-field>
            <span v-if="message != ''" class="text-center red--text">
              {{ message }}
            </span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn type="submit" color="secondary" :loading="loading">
              Ingresar
            </v-btn>
            <v-btn color="error" @click="dialogLoggin = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
  import Service from "../utils/general";
  import { mapActions } from "vuex";
  export default {
    name: "toolbar",
    data: () => ({
      icon_fs: "mdi-fullscreen",
      showPassword: false,
      dialogLoggin: false,
      loading: false,
      message: "",
      user: {
        username: "",
        password: "",
      },
      vrules: {
        required: value => !!value || "Requerido",
        usuario_min5: value => value.length >= 5 || "Mínimo 5 caracteres",
        password_min5: value => value.length >= 5 || "Mínimo 5 caracteres",
      },
    }),
    computed: {
      isAuth() {
        return this.$store.getters.isAuthenticated;
      },
      userauth() {
        return this.$store.getters.getUser;
      },
    },
    methods: {
      ...mapActions(["toast"]),
      drawerToggle() {
        window.getApp.$emit("DRAWER_TOGGLE");
      },
      fullScreen() {
        let doc = window.document;
        let docEl = doc.documentElement;

        let requestFullScreen =
          docEl.requestFullscreen ||
          docEl.mozRequestFullScreen ||
          docEl.webkitRequestFullScreen ||
          docEl.msRequestFullscreen;
        let cancelFullScreen =
          doc.exitFullscreen ||
          doc.mozCancelFullScreen ||
          doc.webkitExitFullscreen ||
          doc.msExitFullscreen;

        if (
          !doc.fullscreenElement &&
          !doc.mozFullScreenElement &&
          !doc.webkitFullscreenElement &&
          !doc.msFullscreenElement
        ) {
          requestFullScreen.call(docEl);
          this.icon_fs = "mdi-fullscreen-exit";
        } else {
          cancelFullScreen.call(doc);
          this.icon_fs = "mdi-fullscreen";
        }
      },

      showDialogLoggin() {
        this.dialogLoggin = true;
        this.loading = false;
        this.user = {
          username: "",
          password: "",
        };
        if (this.$refs.lform) this.$refs.lform.resetValidation();
      },
      login() {
        this.message = "";
        if (this.$refs.lform.validate()) {
          this.loading = true;
          console.log(this.user);

          this.$store
            .dispatch("login", this.user)
            .then(response => {
              this.loading = false;
              if (response.status === "success") {
                this.dialogLoggin = false;
                /* if (this.$router.currentRoute.name != "") {
                this.$router.push({ name: "evaluacion" });
              } */
                this.toast({ color: "success", text: response.msg });
                window.location.reload();
              } else {
                this.toast({ color: "warning", text: response.msg });
              }
            })
            .catch(error => {
              this.loading = false;
              this.message = error.msg;
              this.toast({ color: "error", text: error.msg });
            });
        }
      },

      logout() {
        this.$store.dispatch("logout").then(() => {
          if (this.$router.currentRoute.name != "root") {
            this.$router.replace({ name: "root" });
          }
          window.location.reload();
          Service.removeAll();
        });
      },

      perfil() {
        this.$router.push("/perfil");
      },
    },
  };
</script>
