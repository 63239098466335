import Vue from "vue";
import Axios from "axios";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import HighchartsVue from "highcharts-vue";

Vue.use(HighchartsVue);

Vue.filter("formatdate", function (value) {
  if (!value) return "";
  return value.substring(0, 10).split("-").reverse().join("/");
});

Vue.prototype.$axios = Axios;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
